var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": false,
      "is-centered": ""
    }
  }, [_c('c-modal-content', {
    ref: "content",
    attrs: {
      "max-width": ['90%', '900px'],
      "max-height": "90vh",
      "margin-block": "0px",
      "padding-inline": ['0px', '16px'],
      "border-radius": "12px"
    }
  }, [_c('c-modal-header', [_c('c-flex', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        '.step': {
          w: '100%',
          h: '8px',
          maxWidth: '156px',
          borderRadius: '20px',
          backgroundColor: 'superLightGray.900',
          marginRight: '12px',
          ':last-child': {
            marginRight: '0'
          },
          '&.done': {
            backgroundColor: 'primary.400'
          }
        }
      },
      expression: "{\n          '.step': {\n            w: '100%',\n            h: '8px',\n            maxWidth: '156px',\n            borderRadius: '20px',\n            backgroundColor: 'superLightGray.900',\n            marginRight: '12px',\n            ':last-child': {\n              marginRight: '0',\n            },\n            '&.done': {\n              backgroundColor: 'primary.400',\n            },\n          },\n        }"
    }],
    attrs: {
      "w": "100%",
      "mt": "12px",
      "mb": "16px",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, _vm._l(6, function (_, index) {
    return _c('c-box', {
      key: index,
      staticClass: "step",
      class: {
        done: index < _vm.page
      }
    });
  }), 1), _c('c-text', {
    attrs: {
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "150%",
      "color": "#008C81",
      "text-align": "center"
    }
  }, [_vm._v(" FORMULIR GIZI (" + _vm._s(_vm.page) + "/6) ")]), _vm.selectedQuizionary ? _c('c-text', {
    attrs: {
      "font-weight": "bold",
      "font-size": "20px",
      "line-height": "150%",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.selectedQuizionary.title) + " ")]) : _vm._e()], 1), _c('c-modal-close-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          backgroundColor: 'danger.500'
        }
      },
      expression: "{\n        ':hover': {\n          backgroundColor: 'danger.500',\n        },\n      }"
    }],
    attrs: {
      "border-radius": "32px",
      "color": "#FFF",
      "background-color": "danger.400",
      "top": "6px",
      "right": "6px"
    },
    on: {
      "click": _vm.onClose
    }
  }), _c('c-modal-body', {
    attrs: {
      "overflow": "auto"
    }
  }, [_vm.isLoading ? _c('c-box', {
    attrs: {
      "align": "center"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400"
    }
  }, [_vm._v(" Memuat data formulir gizi... ")])], 1) : _c('c-box', [_vm.selectedQuizionary && _vm.page !== 5 ? _c('QuizionaryDetail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "data": _vm.selectedQuizionary.data
    }
  }) : _vm._e(), _vm.selectedQuizionary && _vm.page === 5 ? [_c('c-text', {
    attrs: {
      "font-family": "Roboto, sans-serif",
      "font-weight": ['700', '500'],
      "font-size": ['14px', '16px'],
      "color": "#333333",
      "pb": ['16px', '16px'],
      "text-align": "center"
    }
  }, [_vm._v(" Silahkan tuliskan makanan yang kamu konsumsi hari ini, juga termasuk minuman selain air mineral ")]), _c('Quizionary5Detail', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "data": _vm.selectedQuizionary.data,
      "mt": "10px"
    }
  })] : _vm._e()], 2)], 1), _c('c-modal-footer', {
    attrs: {
      "padding-block": "8px",
      "gap": "10px",
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "d": "flex",
      "grid-gap": "5px",
      "px": "30px",
      "py": "10px",
      "rounded": "full",
      "variant-color": "primary",
      "variant": "outline",
      "w": "50%",
      "h": ['40px', '45px'],
      "font-size": ['14px', '16px'],
      "disabled": _vm.page === 1 || _vm.isLoading
    },
    on: {
      "click": _vm.onPrevious
    }
  }, [_c('c-text', [_vm._v("Sebelumnya")])], 1), _c('c-button', {
    attrs: {
      "d": "flex",
      "grid-gap": "5px",
      "px": "30px",
      "py": "10px",
      "rounded": "full",
      "variant-color": "primary",
      "w": "50%",
      "h": ['40px', '45px'],
      "font-size": ['14px', '16px'],
      "disabled": _vm.page >= 6 || _vm.isLoading
    },
    on: {
      "click": _vm.onNext
    }
  }, [_c('c-text', [_vm._v("Selanjutnya")])], 1)], 1)], 1), _c('c-modal-overlay'), _c('ModalSuccess', {
    attrs: {
      "title": "Formulir gizi belum diisi klien",
      "note": "",
      "image": "https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/formulir-gizi-belum-diisi-klien.webp?updatedAt=1690423101612",
      "is-open": _vm.isOpenModal,
      "button-text": "Oke"
    },
    on: {
      "close": _vm.handleCloseEmptyQuestionnaire
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }