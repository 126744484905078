<template>
  <c-box>
    <c-button
      font-size="18px"
      font-weight="500"
      variant-color="secondary"
      color="primary.400"
      background-color="#F4CC46"
      border-radius="calc(infinity * 1px)"
      size="lg"
      :disabled="disabled"
      @click="onOpenDuplicate"
    >
      Duplikasi
    </c-button>
    <BaseModal
      :is-open="isOpenModalDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
      size="500px"  
    >
      <template #header>
        <c-flex
          width="100%"
          justify-content="center"
          padding="16px"
        >
          Duplikasi Panduan Makan
          <!--          Duplikasi Hari Ke- {{ month }}-->
        </c-flex>
      </template>
      <template #body>
        <c-flex
          width="100%"
          flex-direction="column"
          padding-inline="16px"
          gap="12px"
        >
          <FormSelect
            v-model="targetMonth"
            label="Tujuan Duplikasi"
            placeholder="Bulan ke-"
            :options="optionsTargetMonth"
            is-without-margin-bottom
            :is-disabled="isLoadingSubmit"
          />

          <c-box
            position="relative"
          >
            <c-text
              color="neutral.darkGray"
              font-size="16px"
            >
              Data Duplikasi
            </c-text>
            <c-box
              v-for="key in Object.keys(optionsDuplication)"
              :key="key"
              border-bottom-width="1px"
              border-bottom-color="lightGray.900"
            >
              <c-flex
                v-chakra="{
                  ':hover .info': {
                    display: 'block',
                  }
                }"
                position="relative"
                width="100%"
                align-items="center"
                gap="16px"
                padding="16px"
                :cursor="isSelectedMonthDisabled || isDisabledSubmit || isSelectedSameMonth ? 'not-allowed' : 'pointer'"
                @click="onToggleMealTime(key)"
              >
                <!--                <c-box-->
                <!--                  v-if="targetMonth && (optionsDuplication[key].isSkipped)"-->
                <!--                  class="info"-->
                <!--                  display="none"-->
                <!--                  position="absolute"-->
                <!--                  top="50%"-->
                <!--                  left="100%"-->
                <!--                  width="max-content"-->
                <!--                  max-width="120px"-->
                <!--                  transform="translateY(-50%)"-->
                <!--                  transition-duration="500ms"-->
                <!--                  background-color="neutral.darkGray"-->
                <!--                  color="neutral.white"-->
                <!--                  padding="4px 6px"-->
                <!--                  font-size="12px"-->
                <!--                  font-weight="500"-->
                <!--                  border-radius="4px"-->
                <!--                >-->
                <!--                  {{ optionsDuplication[key].isSkipped ? 'Jadwal ini diskip' : `Sudah terduplikasi hari ke- ${optionsDuplication[key].parentDay}` }}-->
                <!--                </c-box>-->
                <c-checkbox
                  size="lg"
                  variant-color="primary"
                  :pointer-events="'none'"
                  :is-checked="optionsDuplication[key].isChecked"
                  :disabled="isSelectedMonthDisabled || isDisabledSubmit || isSelectedSameMonth"
                />
                <c-text
                  :color="isSelectedMonthDisabled || isDisabledSubmit || isSelectedSameMonth ? 'neutral.lightGray' : optionsDuplication[key].isChecked ? 'primary.400' : 'neutral.superDarkGray'"
                  font-weight="500"
                >
                  {{ optionsDuplication[key].label }}
                </c-text>
              </c-flex>
            </c-box>
          </c-box>
        </c-flex>
      </template>
      <template #footer>
        <c-grid
          width="100%"
          template-columns="repeat(2, 1fr)"
          padding="8px"
          gap="16px"
        >
          <BaseButton
            variant="outlined"
            :is-disabled="isLoadingSubmit"
            @click="onCloseDuplicate"
          >
            Batal
          </BaseButton>

          <BaseButton
            :is-disabled="isSelectedMonthDisabled || isDisabledSubmit || isSelectedSameMonth"
            :is-loading="isLoadingSubmit"
            @click="onOpenConfirmDuplicate"
          >
            Duplikasi
          </BaseButton>
        </c-grid>
      </template>
    </BaseModal>

    <ModalSimpleInfo
      :is-open="!!isOpenConfirmDuplicate"
      :image="require('@/assets/images/image-question.svg')"
      :title="`Data ${messagePopupConfirmDuplicate} akan diduplikasi ke Panduan Makan Bulan ke-${targetMonth}`"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            color="primary"
            rounded="1000px"
            width="100%"
            variant="outlined"
            :is-disabled="isLoadingSubmit"
            @click="onCloseConfirmDuplicate"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            :is-loading="isLoadingSubmit"
            @click="onSubmitDuplicate"
          >
            Ya
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="!!isOpenErrorDuplicate"
      :image="require('@/assets/images/image-warning.svg')"
      title="Duplikasi gagal"
      :description="errorMessage"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
          justify-content="center"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="250px"
            @click="isOpenErrorDuplicate = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="isOpenSuccessDuplicate"
      :image="require('@/assets/images/success-image.svg')"
      title="Duplikasi berhasil"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
          justify-content="center"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="250px"
            @click="$router.go(0)"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import BaseButton from '@/components/elements/base-button.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import { CBox, CButton, CCheckbox, CFlex, CGrid, CText } from '@chakra-ui/vue'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'

export default {
  name: 'ModalDuplicateMealPlan',
  components: {
    CButton,
    ModalSimpleInfo,
    CFlex,
    CText,
    CBox,
    CCheckbox,
    CGrid,
    BaseButton,
    BaseModal,
    FormSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    listMonth: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpenModalDuplicate: false,
      isLoadingSubmit: false,
      targetMonth: null,
      optionsDuplication: {
        profil_gizi: {
          label: 'Profil Gizi',
          isChecked: false,
        },
        rencana_makan: {
          label: 'Rencana Makan',
          isChecked: false,
        },
      },
      isOpenConfirmDuplicate: false,
      isOpenSuccessDuplicate: false,
      isOpenErrorDuplicate: false,
      errorMessage: '',
    }
  },
  computed: {
    messagePopupConfirmDuplicate() {
      return Object.values(this.optionsDuplication)?.filter((v) => v?.isChecked)?.map((it) => it.label).join(' / ')
    },
    month() {
      return this.$route.params.month ?? 1
    },
    isSelectedSameMonth() {
      return parseInt(this.targetMonth) === parseInt(this.month)
    },
    isDisabledSubmit() {
      return !this.targetMonth
    },
    optionsTargetMonth() {
      return this.listMonth?.reduce((acc, curr) => {
        if (parseInt(this.month) === parseInt(curr.month)) {
          return acc
        }
        acc.push({
          label: `Bulan ke-${curr?.month}`,
          value: curr?.month,
        })
        return acc
      }, [])
    },
    isSelectedMonthDisabled() {
      return this.listMonth?.find((v) => v?.month === parseInt(this.targetMonth))?.isDisabled
    },
  },
  methods: {
    onOpenDuplicate() {
      if (!this.disabled) {
        this.isOpenModalDuplicate = true
      }
    },
    onCloseDuplicate() {
      if (!this.isLoadingSubmit) {
        this.isOpenModalDuplicate = false
        this.targetMonth = null
        this.errorMessage = ''
        this.optionsDuplication = {
          profil_gizi: {
            label: 'Profil Gizi',
            isChecked: false,
          },
          rencana_makan: {
            label: 'Rencana Makan',
            isChecked: false,
          },
        }
      }
    },
    onToggleMealTime(key) {
      if (this.isSelectedMonthDisabled || this.isDisabledSubmit || this.isSelectedSameMonth) {
        return
      }
      this.optionsDuplication[key].isChecked = !this.optionsDuplication[key].isChecked
    },
    onOpenConfirmDuplicate() {
      this.isOpenConfirmDuplicate = true
    },
    onCloseConfirmDuplicate() {
      if (!this.isLoadingSubmit) {
        this.isOpenConfirmDuplicate = false
      }
    },
    async onSubmitDuplicate() {
      if (!this.id || this.isLoadingSubmit) {
        return
      }

      try {
        const payload = {
          id: this.id,
          month: parseInt(this.targetMonth),
          mealPlan: this.optionsDuplication?.rencana_makan?.isChecked ? 1 : 0,
          nutritionalProfile: this.optionsDuplication?.profil_gizi?.isChecked ? 1 : 0,
        }

        this.isLoadingSubmit = true
        await reqNutriMealPlan.postMealPlanDuplicate(this.$store.getters.axios, payload)
        this.isOpenSuccessDuplicate = true
      } catch(err) {
        this.isOpenErrorDuplicate = true
        this.errorMessage = err?.response?.data?.message
      } finally {
        this.isLoadingSubmit = false
        this.onCloseConfirmDuplicate()
        this.onCloseDuplicate()
      }
    },
  },
}
</script>
