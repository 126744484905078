<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="false"
    is-centered
  >
    <c-modal-content
      ref="content"
      :max-width="['90%', '900px']"
      max-height="90vh"
      margin-block="0px"
      :padding-inline="['0px', '16px']"
      border-radius="12px"
    >
      <c-modal-header>
        <c-flex
          v-chakra="{
            '.step': {
              w: '100%',
              h: '8px',
              maxWidth: '156px',
              borderRadius: '20px',
              backgroundColor: 'superLightGray.900',
              marginRight: '12px',
              ':last-child': {
                marginRight: '0',
              },
              '&.done': {
                backgroundColor: 'primary.400',
              },
            },
          }"
          w="100%"
          mt="12px"
          mb="16px"
          justify-content="space-between"
          align-items="center"
        >
          <c-box
            v-for="(_, index) in 6"
            :key="index"
            class="step"
            :class="{ done: index < page }"
          />
        </c-flex>
        
        <!-- <c-text
          v-if="program"
          font-weight="500"
          font-size="18px"
          line-height="150%"
          color="#008C81"
          text-align="center"
          mb="30px"
        >
          Program {{ program.name }}
        </c-text> -->

        <c-text
          font-weight="500"
          font-size="16px"
          line-height="150%"
          color="#008C81"
          text-align="center"
        >
          FORMULIR GIZI ({{ page }}/6)
        </c-text>

        <c-text
          v-if="selectedQuizionary"
          font-weight="bold"
          font-size="20px"
          line-height="150%"
          text-align="center"
        >
          {{ selectedQuizionary.title }}
        </c-text>
      </c-modal-header>
      <c-modal-close-button
        v-chakra="{
          ':hover': {
            backgroundColor: 'danger.500',
          },
        }"
        border-radius="32px"
        color="#FFF"
        background-color="danger.400"
        top="6px"
        right="6px"
        @click="onClose"
      />
      <!-- <c-button
        position="absolute"
        top="8px"
      >
        <c-icon
          name="close"
          size="24px"
        />
      </c-button> -->
      <c-modal-body
        overflow="auto"
      >
        <c-box
          v-if="isLoading"
          align="center"
        >
          <c-spinner
            thickness="4px"
            speed="0.65s"
            empty-color="green.200"
            color="primary.400"
            size="xl"
          />
          <c-text color="primary.400">
            Memuat data formulir gizi...
          </c-text>
        </c-box>

        <c-box v-else>
          <QuizionaryDetail
            v-if="selectedQuizionary && page !== 5"
            v-chakra
            :data="selectedQuizionary.data"
          />
          <template v-if="selectedQuizionary && page === 5">
            <c-text
              font-family="Roboto, sans-serif"
              :font-weight="['700', '500']"
              :font-size="['14px', '16px']"
              color="#333333"
              :pb="['16px', '16px']"
              text-align="center"
            >
              Silahkan tuliskan makanan yang kamu konsumsi hari ini, juga termasuk minuman selain air mineral
            </c-text>
            <Quizionary5Detail
              v-chakra
              :data="selectedQuizionary.data"
              mt="10px"
            />
          </template>
        </c-box>
      </c-modal-body>
      <c-modal-footer
        padding-block="8px"
        gap="10px"
        justify-content="center"
      >
        <c-button
          d="flex"
          grid-gap="5px"
          px="30px"
          py="10px"
          rounded="full"
          variant-color="primary"
          variant="outline"
          w="50%"
          :h="['40px', '45px']"
          :font-size="['14px', '16px']"
          :disabled="page === 1 || isLoading"
          @click="onPrevious"
        >
          <c-text>Sebelumnya</c-text>
        </c-button>
        <c-button
          d="flex"
          grid-gap="5px"
          px="30px"
          py="10px"
          rounded="full"
          variant-color="primary"
          w="50%"
          :h="['40px', '45px']"
          :font-size="['14px', '16px']"
          :disabled="page >= 6 || isLoading"
          @click="onNext"
        >
          <c-text>Selanjutnya</c-text>
        </c-button>
      </c-modal-footer>
    </c-modal-content>
    <c-modal-overlay />
    <ModalSuccess 
      title="Formulir gizi belum diisi klien"
      note=""
      image="https://ik.imagekit.io/dietela/pwa_webp/nutrisionist/formulir-gizi-belum-diisi-klien.webp?updatedAt=1690423101612"
      :is-open="isOpenModal"
      button-text="Oke"
      @close="handleCloseEmptyQuestionnaire"
    />
  </c-modal>
</template>

<script>
import { sortMealCategory } from '@/utils/sort-meal-category'
import _ from 'lodash'
import QuizionaryDetail from '@/components/quizionary/detail.vue'
import Quizionary5Detail from '@/components/quizionary/detail-kuis-5-alt.vue'
import quizionaryLabelMap from '@/utils/quizionary-label-map'
import quizionaryValueMap from '@/utils/quizionary-value-map'
import ModalSuccess from '@/components/widgets/modal-success'
import { mapActions } from 'vuex'

export default {
  name: 'ModalContentQuestionnaire',
  components: {
    QuizionaryDetail,
    Quizionary5Detail,
    ModalSuccess,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: 'nutritionist',
    },
  },
  emits: ['close'],
  data() {
    return {
      kuisioner: null,
      isLoading: false,
      isOpenModal: false,
      page: 1,
    }
  },
  computed: {
    programId() {
      return this.$route.params.programId
    },
    // page() {
    //   return +(this.$route.query.page ?? 1)
    // },
    selectedQuizionary() {
      return this.quizionary[this.page]
      // return null;
    },
    program() {
      if (this.kuisioner && this.kuisioner?.program) return this.kuisioner.program
      return null
    },
    quizionary() {
      if (this.kuisioner == null || _.isEmpty(this.kuisioner)|| this.kuisioner?.isDraft) {
        return {}
      }

      let page5 = [...this.kuisioner.foodRecords].sort((a, b) => sortMealCategory(a.category, b.category))

      // eslint-disable-next-line no-unreachable
      return {
        1: {
          title: 'Identitas & Data Umum',
          data: Object.entries(_.omit(this.kuisioner?.generalData, 'id')).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('general_data'),
        },
        2: {
          title: 'Data Antropometri (Ukuran & Komposisi Tubuh)',
          data: Object.entries(
            _.omit(this.kuisioner?.anthropometryData, ['id', 'bodyWater', 'boneMass', 'basalMetabolicRate']),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('anthropometry_data'),
        },
        3: {
          title: 'Data Pola & Riwayat Makan',
          data: Object.entries(
            _.omit(this.kuisioner?.dietAndEatingHistory, ['id', 'breakfastHabits', 'breakfastFoodType']),
          ).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('diet_and_eating_history'),
        },
        4: {
          title: 'Data Aktivitas Harian',
          data: Object.entries(_.omit(this.kuisioner?.dailyActivities, ['id', 'activeLevel'])).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            }),
          ),
          dinamis: this.getDinamisQuestionFor('daily_activities'),
        },
        5: {
          title: 'Riwayat Makan 24 Jam Terakhir',
          data: Object.entries(_.omit(page5, 'id')).map(([key, value], index) => ({
            order: index,
            label: quizionaryLabelMap[key] ?? key,
            value: quizionaryValueMap[key]?.(value) ?? value,
          })),
          dinamis: this.getDinamisQuestionFor('food_records'),
        },
        // 6: {
        //   title: 'Kemampuan Aktivitas Fisik',
        //   data: Object.entries(
        //     _.omit(this.kuisioner.physicalActivityAbilities, 'id'),
        //   ).map(([key, value], index) => ({
        //     order: index,
        //     label: quizionaryLabelMap[key] ?? key,
        //     value: quizionaryValueMap[key]?.(value) ?? value,
        //   })),
        //   dinamis: this.getDinamisQuestionFor('physical_activity_abilities'),
        // },
        6: {
          title: 'Kondisi Pribadi & Riwayat Medis',
          data: Object.entries(_.omit(this.kuisioner?.medicalHistory, 'id', 'specialMedicine')).map(
            ([key, value], index) => ({
              order: index,
              label: quizionaryLabelMap[key] ?? key,
              value: quizionaryValueMap[key]?.(value) ?? value,
            }),  
          ),
          dinamis: this.getDinamisQuestionFor('medical_history'),
        },
      }
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val) {
          this.init()
        }
      },
    },
  },
  methods: {
    ...mapActions({
      getClientQuestionnaire: 'kuisioner/getV2ClientQuestionnaire',
      getNutriQuestionnaire: 'kuisioner/getV2NutriQuestionnaire',
    }),
    init() {
      this.isLoading = true
      if (this.role == 'client') {
        this.getClientQuestionnaire(this.programId)
          .then((it) => {
            if (_.isEmpty(it) || (!_.isEmpty(it) && it?.isDraft)) {
              this.handleOpenEmptyQuestionnaire()
            }
            this.kuisioner = it
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data?.message ?? 'When get data questionnaire.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => this.isLoading = false)
      } else {
        this.getNutriQuestionnaire(this.programId)
          .then((it) => {
            if (_.isEmpty(it) || (!_.isEmpty(it) && it?.isDraft)) {
              this.handleOpenEmptyQuestionnaire()
            }
            this.kuisioner = it
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data?.message ?? 'When get data questionnaire.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => this.isLoading = false)
      }
    },
    getDinamisQuestionFor(category) {
      return this.kuisioner.answers
        .filter((it) => it.questions.category === category)
        .map((item, index) => ({
          order: index,
          label: item.question,
          value: item.answer,
        }))
    },
    handleOpenEmptyQuestionnaire() {
      this.isOpenModal = true
    },
    handleCloseEmptyQuestionnaire() {
      this.isOpenModal = false
    },
    onPrevious() {
      this.page = this.page - 1
    },
    onNext() {
      this.page = this.page + 1
    },
    onClose() {
      this.$emit('close')
    },
  },
}
</script>
