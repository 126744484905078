var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-button', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "variant-color": "secondary",
      "color": "primary.400",
      "background-color": "#F4CC46",
      "border-radius": "calc(infinity * 1px)",
      "size": "lg",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.onOpenDuplicate
    }
  }, [_vm._v(" Duplikasi ")]), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": "500px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "justify-content": "center",
            "padding": "16px"
          }
        }, [_vm._v(" Duplikasi Panduan Makan ")])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "padding-inline": "16px",
            "gap": "12px"
          }
        }, [_c('FormSelect', {
          attrs: {
            "label": "Tujuan Duplikasi",
            "placeholder": "Bulan ke-",
            "options": _vm.optionsTargetMonth,
            "is-without-margin-bottom": "",
            "is-disabled": _vm.isLoadingSubmit
          },
          model: {
            value: _vm.targetMonth,
            callback: function callback($$v) {
              _vm.targetMonth = $$v;
            },
            expression: "targetMonth"
          }
        }), _c('c-box', {
          attrs: {
            "position": "relative"
          }
        }, [_c('c-text', {
          attrs: {
            "color": "neutral.darkGray",
            "font-size": "16px"
          }
        }, [_vm._v(" Data Duplikasi ")]), _vm._l(Object.keys(_vm.optionsDuplication), function (key) {
          return _c('c-box', {
            key: key,
            attrs: {
              "border-bottom-width": "1px",
              "border-bottom-color": "lightGray.900"
            }
          }, [_c('c-flex', {
            directives: [{
              name: "chakra",
              rawName: "v-chakra",
              value: {
                ':hover .info': {
                  display: 'block'
                }
              },
              expression: "{\n                ':hover .info': {\n                  display: 'block',\n                }\n              }"
            }],
            attrs: {
              "position": "relative",
              "width": "100%",
              "align-items": "center",
              "gap": "16px",
              "padding": "16px",
              "cursor": _vm.isSelectedMonthDisabled || _vm.isDisabledSubmit || _vm.isSelectedSameMonth ? 'not-allowed' : 'pointer'
            },
            on: {
              "click": function click($event) {
                return _vm.onToggleMealTime(key);
              }
            }
          }, [_c('c-checkbox', {
            attrs: {
              "size": "lg",
              "variant-color": "primary",
              "pointer-events": 'none',
              "is-checked": _vm.optionsDuplication[key].isChecked,
              "disabled": _vm.isSelectedMonthDisabled || _vm.isDisabledSubmit || _vm.isSelectedSameMonth
            }
          }), _c('c-text', {
            attrs: {
              "color": _vm.isSelectedMonthDisabled || _vm.isDisabledSubmit || _vm.isSelectedSameMonth ? 'neutral.lightGray' : _vm.optionsDuplication[key].isChecked ? 'primary.400' : 'neutral.superDarkGray',
              "font-weight": "500"
            }
          }, [_vm._v(" " + _vm._s(_vm.optionsDuplication[key].label) + " ")])], 1)], 1);
        })], 2)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(2, 1fr)",
            "padding": "8px",
            "gap": "16px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onCloseDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "is-disabled": _vm.isSelectedMonthDisabled || _vm.isDisabledSubmit || _vm.isSelectedSameMonth,
            "is-loading": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onOpenConfirmDuplicate
          }
        }, [_vm._v(" Duplikasi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": !!_vm.isOpenConfirmDuplicate,
      "image": require('@/assets/images/image-question.svg'),
      "title": "Data ".concat(_vm.messagePopupConfirmDuplicate, " akan diduplikasi ke Panduan Makan Bulan ke-").concat(_vm.targetMonth)
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onCloseConfirmDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingSubmit
          },
          on: {
            "click": _vm.onSubmitDuplicate
          }
        }, [_vm._v(" Ya ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": !!_vm.isOpenErrorDuplicate,
      "image": require('@/assets/images/image-warning.svg'),
      "title": "Duplikasi gagal",
      "description": _vm.errorMessage
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px",
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "250px"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenErrorDuplicate = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenSuccessDuplicate,
      "image": require('@/assets/images/success-image.svg'),
      "title": "Duplikasi berhasil"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px",
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "250px"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.go(0);
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }